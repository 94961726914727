import React from "react";
import cx from "classnames";
import { Button } from "@elevio/styles/lib/components/buttons";
import useSearchResults from "@elevio/kb-kit/lib/hooks/useSearchResults";

type Props = JSX.IntrinsicElements["button"];
const SearchMore: React.FC<Props> = ({ className, ...props }) => {
  const { isFetching, loadMore, hasMore } = useSearchResults();
  if (!hasMore) return null;

  return (
    <Button
      appearance="primary"
      className={cx("ShowMoreButton", className)}
      disabled={isFetching || !hasMore}
      onClick={loadMore}
      {...props}
    >
      Load more
      {isFetching && "..."}
    </Button>
  );
};

export default SearchMore;
