import React from 'react';
import cx from 'classnames';
import { addClassName } from '@elevio/styles/lib/utils/classes';

type Props = {
  title?: string;
  className?: string;
};

const ErrorPage: React.FC<Props> = ({
  children,
  title = 'Error',
  className,
}) => (
  <section
    className={cx(addClassName('error-page'), 'px-6 text-center', className)}
  >
    <div className="max-w-3xl mx-auto py-12">
      <h1 className="text-5xl font-medium mb-2">{title}</h1>
      {children}
    </div>
  </section>
);

export default ErrorPage;
