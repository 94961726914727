import * as React from "react";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ErrorPage from "../components/ErrorPage";
import SearchInput from "../components/SearchInput";

const Page = () => (
  <PageLayout header={<Header />} footer={<Footer />}>
    <ErrorPage title="Whoops!">
      <p className="text-xl mb-12">
        The page you are looking for cannot be found.
      </p>

      <SearchInput
        placeholder="Search our customer portal..."
        className="mb-12"
      />

      <p className="text-grey-light">
        Need more help?{" "}
        <a href="#" className="underline">
          Submit a support request
        </a>
      </p>
    </ErrorPage>
  </PageLayout>
);

export default Page;
