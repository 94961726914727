import * as React from 'react';

import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ErrorPage from '../components/ErrorPage';

const Page = () => (
  <PageLayout header={<Header />} footer={<Footer />}>
    <ErrorPage title="Whoops!">
      <p className="text-xl mb-12">Sorry, there has been a problem.</p>
    </ErrorPage>
  </PageLayout>
);

export default Page;
