import React from 'react';
import cx from 'classnames';
import { addClassName } from '@elevio/styles/lib/utils/classes';
import Alert from '../Alert';

type Props = {
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

const PageLayout: React.FC<Props> = ({
  children,
  className,
  header,
  footer,
}) => (
  <div
    className={cx(
      addClassName('page-layout'),
      'items-stretch flex flex-col h-full antialiased',
      className
    )}
  >
    <div className="flex flex-shrink-0 flex-grow bg-background flex-col">
      {header}
      <Alert />
      <div className="h-full">{children}</div>
    </div>
    {footer}
  </div>
);

export default PageLayout;
