import React from 'react';
import cx from 'classnames';
import { addClassName } from '@elevio/styles/lib/utils/classes';
// import LanguagePicker from './LanguagePicker';
// import LoginLogout from './LoginLogout';

type Props = {
  className?: string;
};

const Footer: React.FC<Props> = ({ children, className, ...props }) => (
  <footer
    className={cx(addClassName('footer'), 'bg-white flex-shrink-0', className)}
  >
    <div className="w-full lg:max-w-6xl mx-auto">
      <nav className="flex flex-col sm:flex-row items-center justify-between p-6">
        <div className="flex items-center flex-shrink-0 mr-6 mb-6 sm:mb-0">
          <span className="text-grey-lighter">
            &copy; {new Date().getFullYear()} Comcate, Inc.
          </span>
        </div>

        <div className="flex items-center">
          {/* <a className="mr-6 text-grey-lighter" href="#">
            Our Site
          </a> */}
          {/* <LoginLogout className="mr-6 text-grey-lighter" /> */}
          {/* <LanguagePicker /> */}
        </div>
      </nav>
    </div>
  </footer>
);

export default Footer;
