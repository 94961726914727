import React from "react";
import cx from "classnames";
import * as Meta from "@elevio/kb-kit/lib/toolkit/meta";

type Props = {
  className?: string;
};

export const Logo: React.FC<Props> = ({ children, className, ...props }) => (
  <Meta.HomeLink className={cx("block", className)}>
    <h1 className="text-3xl">
      <img
        src="https://cdn.elev.io/file/uploads/waldport-logo.png"
        className="w-32"
      />
    </h1>
  </Meta.HomeLink>
);
